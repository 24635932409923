<template>
  <div class="ab-tests">
    <h3>AB Tests</h3>
    <template v-if="fetchingTests">
      <div
        v-for="index in 3"
        :key="index"
        class="ab-tests__item">
        <skeleton-box height="16" width="25%" />
      </div>
    </template>
    <template v-else>
      <ab-test-item
        v-for="(test, i) in tests"
        :key="test.id"
        :test="test"
        @click="handleEdit(test.id)"
        @status="(s) => handleStatusChange(i, s)" />
      <div class="ab-tests__add" @click="handleEdit('create')">
        <h6>Add Test</h6>
        <el-button
          type="primary"
          class="next-button ab-tests__add-btn"
          icon="el-icon-plus">
          Add
        </el-button>
      </div>
    </template>
  </div>
</template>

<script>
import AbTestItem from '@/components/ab/TestItem';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'Tests',
  components: { AbTestItem, SkeletonBox },
  data() {
    return {
      fetchingTests: false,
      testsInterval: undefined,
      tests: []
    };
  },
  created() {
    this.fetchingTests = true;
    this.loadTests();
    this.testsInterval = setInterval(() => this.loadTests(), 5 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.testsInterval);
  },
  methods: {
    handleEdit(id) {
      this.$router.push({ name: 'ab-integration', params: { id } });
    },
    loadTests() {
      this.$store
        .dispatch('fetchAbTests', {
          orgId: this.$store.getters.organization.id
        })
        .then(tests => {
          this.tests = tests;
        })
        .catch(() => {})
        .finally(() => {
          this.fetchingTests = false;
        });
    },
    handleStatusChange(index, status) {
      const test = this.tests[index];

      this.$store
        .dispatch('saveAbTest', {
          orgId: this.$store.getters.organization.id,
          testId: test.id,
          test: { status }
        })
        .then(response => {
          test.status = status;
          this.$message({
            dangerouslyUseHTMLString: true,
            message: `<strong>${test.name}</strong> status changed to ${status}`,
            type: 'success'
          });
        })
        .catch(e => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: `Failed to update status on <strong>${test.name}</strong>`,
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="scss">
.ab-tests {
  @include page-layout;

  h3 {
    color: $--clb-color__headings;
    margin-top: 0;
  }

  &__item {
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    display: flex;
    align-items: center;
    height: 58px;
    padding: $--clb-space-3;

    .skeleton-box {
      margin: 0;
    }
  }

  .ab-test-item,
  &__item {
    margin-bottom: $--clb-space-4;
  }

  &__add {
    display: flex;
    align-items: center;
    height: 60px;
    line-height: 58px;
    padding: 0 $--clb-space-3;
    border: 1px dashed $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    background: $--clb-skeleton-color;
    transition: $--all-transition;
    cursor: pointer;

    h6 {
      flex: 1 1 0;
      color: $--clb-color-info;
      font-size: $--clb-font-size-sm;
      font-weight: normal;
      width: 100%;
    }

    .ab-tests__add-btn {
      margin: 0;
      padding: 0;
      height: 36px;
      width: 36px;
      font-size: $--clb-font-size-xs;

      span {
        display: none;
      }

      i {
        font-size: $--clb-font-size-sm;
      }

      &:active,
      &:hover,
      &:focus {
        transform: translateY(0) !important;
      }
    }

    &:hover {
      border: 1px solid $--jb-dark-primary-color;
      background: $--color-light;
      transform: translateY(-3px);
      box-shadow: $--box-shadow-light;

      h6 {
        color: $--jb-light-primary-color;
      }
    }
  }
}

@media (min-width: $--sm) {
  .ab-tests {
    h3 {
      margin-bottom: $--clb-layout-1;
    }

    &__add {
      .ab-tests__add-btn {
        width: 108px;

        span {
          display: inline;
        }
      }
    }
  }
}

</style>
