<template>
  <div class="ab-test-item">
    <div class="ab-test-item__top">
      <p class="ab-test-item__title">
        <span class="ab-test-item__title-id hidden-xs"> #{{ test.id }} </span>
        <span class="ab-test-item__title-name"> {{ test.name }} </span>
        <el-tag
          v-if="isNew"
          class="ab-test-item__title-badge"
          type="danger"
          size="mini"
          effect="dark">
          New
        </el-tag>
      </p>
      <div class="ab-test-item__information">
        <strong v-if="test.status == 'running' || test.status == 'completed'">{{ test.status | capitalize }}</strong>
        <template v-else>
          {{ test.status | capitalize }}
        </template>
      </div>
      <div class="ab-test-item__buttons">
        <el-dropdown
          size="small"
          split-button
          type="primary"
          @click="handleItemClick"
          @command="handleStatusChange">
          <i class="el-icon-edit" /><span class="hidden-xs-and-down"> Edit </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="status in availableStatuses" :key="status" :command="status">
              {{ status | capitalize }}
            </el-dropdown-item>
          </el-dropdown-menu>
          </i>
        </el-dropdown>
      </div>
    </div>
    <div class="ab-test-item__bottom">
      <ul>
        <li v-for="variant in variants" :key="variant.variant_id" :class="{ disabled: variant.enabled == 'no' }">
          <div class="name">
            {{ variant.name }}
            <el-tag
              v-if="variant.enabled == 'no'"
              type="plain"
              size="mini"
              effect="dark">
              Disabled
            </el-tag>
            <el-tag
              v-if="variant.variant_id == test.winning_variant"
              type="success"
              size="mini"
              effect="dark">
              Winner
            </el-tag>
          </div>
          <div class="conversion-rate hidden-sm-and-down">
            {{ variant.conversions | money(false, '', '') }}/{{ variant.participants | money(false, '', '') }} ({{ getConversionRate(variant) }})
          </div>
          <el-progress
            :text-inside="true"
            :stroke-width="20"
            :percentage="parseInt(variant.winning_probability)"
            :status="getProgressColor(parseInt(variant.winning_probability))" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Breakpoints from '@/mixins/Breakpoints';
import dateService from '@/services/date';
import moneyService from '@/services/money';

export default {
  name: 'TestItem',
  components: {},
  mixins: [Breakpoints([768])],
  props: {
    test: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statuses: ['pending', 'running', 'cancelled']
    };
  },
  computed: {
    isNew() {
      return dateService.parseISOString(this.test.created) >= new Date(Date.now() - 60 * 60 * 24 * 7 * 1000);
    },
    variants() {
      return this.test.variants;
    },
    availableStatuses() {
      return this.statuses.filter(s => s !== this.test.status);
    }
  },
  methods: {
    handleItemClick() {
      this.$emit('click');
    },
    handleStatusChange(status) {
      this.$emit('status', status);
    },
    getConversionRate(variant) {
      const participants = parseInt(variant.participants) || 1;
      const conversions = parseInt(variant.conversions);

      return moneyService((conversions / participants) * 100, true, '', '%');
    },
    getProgressColor(progress) {
      if (progress >= 70) {
        return 'success';
      } else if (progress >= 40) {
        return undefined;
      } else if (progress >= 20) {
        return 'warning';
      }

      return 'exception';
    }
  }
};
</script>

<style lang="scss">
.ab-test-item {
  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    padding: 0 $--clb-space-3;
    min-height: 58px;
    background-color: $--clb-color-primary__white;
  }

  &__information {
    font-size: $--clb-font-size-xs !important;
    padding: 0 $--clb-space-1;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  &__title {
    flex: 3 1 0;
    font-size: $--clb-font-size-sm !important;

    &-id {
      display: block;
      font-size: $--clb-font-size-xs;
      line-height: $--clb-font-size-sm;
    }

    &-name {
      margin-right: $--clb-space-2;
      font-weight: 600;
    }

    &-badge {
      position: relative;
      top: -2px;
      font-size: $--clb-font-size-xs - 2px;
      margin-right: $--clb-space-1;
    }
  }

  .el-icon-edit {
    margin-right: 5px;
  }

  .el-button--small {
    padding: 9px 6px;

    &:hover {
      transform: none;
    }
  }

  &__bottom {
    border: 1px solid $--clb-border-color-lighter;
    border-radius: 0 0 $--clb-border-radius $--clb-border-radius;
    border-top: none;
    background-color: $--clb-color-primary__white;
    margin: 0 $--clb-space-2;
    padding: $--clb-space-2 $--clb-space-2;

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: $--clb-font-size-sm;
      line-height: 20px;
      padding-bottom: $--clb-space-2;

      .name {
        display: flex;
        flex-grow: 1;

        .el-tag {
          margin-left: $--clb-space-2;
        }
      }

      .conversion-rate {
        width: 25%;
        text-align: right;
        padding-right: $--clb-layout-2;
      }

      &.disabled {
        opacity: 0.6;
      }

      .el-progress {
        width: 30%;

        &-bar__outer {
          border-radius: 0;
        }

        &-bar__inner {
          border-radius: $--clb-border-radius;
        }
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@media (min-width: $--sm) {
  .ab-test-item {
    &__title {
      font-size: $--clb-font-size-base !important;
    }

    &__information {
      font-size: $--clb-font-size-sm !important;
    }

    .el-button--small:not(:last-child) {
      padding: 9px 15px;
    }
  }
}
</style>
